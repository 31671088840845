<template>
  <div>
    <!-- Title -->

    <!-- Social Button -->
    <!-- <AuthSocialButton title="الدخول عن طريق غوغل" @click.native="props.googleLogin">
      <v-icon name="mdi-google" :size="20" class="text-google_business" />
    </AuthSocialButton>
    <AuthSocialButton title="الدخول عن طريق فيس بوك" @click.native="props.facebookLogin">
      <v-icon name="mdi-facebook" :size="20" class="text-facebook" />
    </AuthSocialButton> -->

    <!-- <div class="flex items-center my-4">
      <div class="flex-auto border-b-2 border-gray-200"></div>
      <span class="w-20 text-xs text-gray-300 text-center">أو</span>
      <div class="flex-auto border-b-2 border-gray-200"></div>
    </div> -->

    <!-- Loggin Method Toggle -->
    <div class="flex justify-center mb-3">
      <div class="mevcut-form-title ltr:mr-auto rtl:ml-auto">{{ t('login') }}</div>
      <button
        type="button"
        class="mt-5"
        :aria-label="t('login_with', { with: t('email') })"
        @click="form.method = 'Email'"
      >
        <v-icon
          icon="mdi-email"
          :size="24"
          :class="[
            form.method === 'Email' ? 'text-mevcut-light' : 'text-gray-300'
          ]"
        />
      </button>
      <div class="w-5"></div>
      <button
        type="button"
        class="mt-5"
        :aria-label="t('login_with', { with: t('phone') })"
        @click="form.method = 'Phone'"
      >
        <v-icon
          icon="mdi-phone"
          :size="24"
          :class="[
            form.method === 'Phone' ? 'text-mevcut-light' : 'text-gray-300'
          ]"
        />
      </button>
    </div>

    <!-- Email -->
    <AuthTextField
      v-if="form.method == 'Email'"
      v-model="form.email"
      :label="t('email')"
      placeholder="example@email.com"
      type="email"
      error="login-email"
    />

    <!-- Phone -->
    <LazyAuthPhoneField
      v-if="form.method == 'Phone'"
      id="login-phone-input"
      v-model="form.phone"
      error="login-phone"
    />

    <!-- Password -->
    <AuthTextField
      v-model="form.password"
      :label="t('password')"
      placeholder="********"
      type="password"
      error="login-password"
    />

    <MevcutCheckbox v-model="form.rememberMe" class="mt-3">
      <div class="ltr:ml-2 rtl:mr-2">{{ t('remember_me') }}</div>
    </MevcutCheckbox>

    <!-- Submit -->
    <MevcutResponseError
      class="my-2"
      api-key="login"
      :except="['login-email', 'login-phone', 'login-password']"
    />

    <MevcutSubmitButton
      class="my-2"
      :loading="form.loading"
      @submit="signInHandler"
    />

    <div class="w-full flex justify-center mb-10">
      <MevcutLink
        :with-branch-id-query="false"
        to="/auth/forgot"
        :aria-label="t('password_reset')"
        class="text-nav_color text-sm font-medium"
      >
        {{ t('forgot_password', { mark: t('?') }) }}
      </MevcutLink>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/'
  }
})

const { form, signInHandler } = useLogin()
const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/auth/login',
    t('login'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('login'),
  link: [useSeoCanonical(url + '/auth/login')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('login'), url + '/auth/login')
    ])
  ]
})
</script>
